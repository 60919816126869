
.shows{
    background-color: #111;
}

.fill-screen {
    height: 100%;
    /* position: absolute; */
    left: 0;
    width: 100%;
    overflow: auto;
}
