.dark{
    background-color: #111;
    color: white;
    height : '600px';
    /* width: '900px'; */
    /* width: 700px;
    height:1000px; */
}
.closeButton{

}
.title_background{
    bottom: 10px;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 100%);
    color: white;
    height: 50px;
    padding-top: 10px;
    padding-left: 20px;
}
.title_font{
    font-size: 30px;
}
.info_panel{
    /* height: 100px; */
    background-color: #111;
    grid-template-areas: 
    'year year year year tag tag'
    'desc desc desc desc tag tag'
    'desc desc desc desc tag tag';
    /* position: relative; */
    display: grid;
    grid-gap:10px;
    padding: 10px;
    /* justify-content: space-between; */
}
.last_year{
    color: white;
}
.duration{
    padding-left: 100px;
    color: white;
}
.info_description{
    grid-area: desc;
    color: white;
    font-size: 14px;
}
.info_tag{
    grid-area: tag;
    flex-direction: column;
}
.info_year{
    grid-area: year;
    display: flex;
}
.season_selector{
    background-color: #111;
    padding: 10px;
    padding-top: 20px;
    color:white;
}
.episode_selector_panel{
    background-color: #111;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
}
.episode_list{
    background-color: #111;
    color: white
}
.episode_container div:hover{
    background-color: #383838;
}
.episode_row{
    /* padding: 0px 10px; */
}
.episode_poster{
    height: 113px;
    width: 200px;
}
.episode_number{ 
    font-size: 40px;
    font-weight: bold;
 }
.episode_title{ 
    font-size: 20px;
    font-weight: bold;
}
.episode_desc{
    font-size:14px;
}
