
.row_show {
    margin-left: 40px;
    color: white;
  }
  .row__posters {
    display: flex;
    gap: 20px;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
  }
  .row__posters::-webkit-scrollbar {
    display: none;
  }

  .poster{
    width: 100%;
    max-height: 300px;
  }
  .row__poster {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
    transition: transform 450ms;
  }
  
  .row__poster:hover {
    transform: scale(1.10);
  }
  
  .row__posterLarge {
    max-height: 250px;
  }
  
  .row__posterLarge:hover {
    transform: scale(1.09);
  }
  
  .season_name{
    /* position: absolute; */
    right: 10px;
    top: 100px;
  }
  