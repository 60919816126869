.msg_history{
    /* height: 400px; */
    display:flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    /* max-height: 70%; */
    /* max-height: calc(100%-500px) */
}
.msg_box{
    display: flex;
    flex-direction: column;
    background-color: rgb(41, 41, 41);
    border-radius: 20px;
}
.msg_box_contain{
    padding-top: 3px;
}
.msg_box_info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.msg_box_date{
    padding-right: 10px;
    font-size: 12px;
    color: rgb(136, 134, 134);
}

.msg_username{
    padding-left: 10px;
    font-weight: 600;
    font-size: 12px;
    color: rgb(223, 185, 185);
}
.msg_box_message{
    padding-left: 10px;
    padding-right: 10px;
}
.msg_box_event{
    font-size: 12px;
    color: rgb(136, 134, 134);
    padding-right: 10px;

}

.room_container{
    display: relative;
    flex:1;
    background-color: #111;
    flex-direction: column;
    height: 100%
}
.room_title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.activity_box{
    height: 10px;
}
.chat_box{
    border-color: #e6e6e6;
    border-top: 2px solid rgb(136, 134, 134);
}
.chat_input{
    width: 100%;
    background-color: #111;
    color:rgb(237, 237, 237);
    border: none;
    outline: none;
}
.session_section{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: space-evenly;
}
.session_input{
    width: 6em;
    margin-bottom: 10px;
}
.request_input{
  width: 12em;
  margin-bottom: 15px;
}
.join__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding-top: 0.5rem;
    background-color: rgba(79, 79, 79, 0.5);
    padding-bottom: 0.5rem;
    font-size: 12px;
    width: 8em;
  }
  
  .join__button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.2s;
  }

  .create_room{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .create{
    background-color: rgba(36, 206, 13, 0.5);
  }
  .leave{
    color: rgba(206, 13, 13, 0.7);
    cursor: pointer;
  }
  .leave:hover{
    color: rgba(247, 247, 247, 0.7); 
  }

  /* width */
::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #343a40; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(138, 12, 12); 
  }