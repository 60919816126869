.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Con{
  background-color: #282c34;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Img-hover{
  height: 200px;
  width: 200px;
  background-color:#212529;
  position: relative;
}

.Img-hover-label{
  height: 3px;
  width: 200px;
  bottom: 15px;
  background-color: grey;
  position: absolute;
}

.Img-hover-label-tag{
  margin-left: 2px;
  height: 3px;
  width:35px;
  position: absolute;
  background-color: red;
}

.img-hover-holder{
  position: absolute;
  width: 40px;
  height: 200px;
  z-index: 10;
}
.img-holder{
  position: absolute;
  width: 200px;
  text-align: center;
  margin-top: 5px;
  z-index: 1;
}

/* CARD SITE */
.site{
  align-items: flex-end;
  font-weight: bold;
  color: azure;
  text-align: center;
  display: flex;
  bottom: 0px;
  padding: 2px;
  font-size: 9px;
  /* margin-bottom: -3px; */
  /* margin-right: -4px; */
}
.site-left-box{
  text-transform: uppercase;
    display: flex;
    align-items: center;
    background: rgb(0, 0, 0);
    text-align: center;
    border-radius: 3px 0px 0px 3px;
}
a.site-left-box:hover{
  background: rgb(45, 45, 45);
  color: white;
  text-decoration: none;
}

.site-right-box{
  background: rgb(45, 45, 45);
  border-radius: 0px 3px 3px 0px;
  align-items: center;
  /* margin-right: -7px; */
  
}
a.site-right-box:hover{
  background: rgb(0, 0, 0);
  color: white;
  text-decoration: none;
}
.site-scene{
font-size: 16px;
}

.site-card{
  font-size: 9px;
  position: relative; 
  bottom:0px;
  right:5px;
}
a.scene-card-link{
  color: black;
}
a.scene-card-link:hover{
  color: grey;
  text-decoration: none;
}


/* CARD STATUS */
.scene-status{
  display: flex;
}

.invalid-input {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.sorter{
  background-color:#dc3545;
  color: red;
}

.scrollcards {
  /* background-color: #fff; */
  overflow: auto;
  white-space: nowrap;
}

::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    background: transparent;
}

div.scrollcards .relcard {
    display: inline-block;
    /* padding: 14px; */
    text-decoration: none;
    height: 300px; 
    width: 400px;
}
.relcard {
  padding: 10px;
  transition: .6s ease;
}

.relcard:hover {
  transform: scale(1.05);
}

.autoplay_button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 700;
  border-radius: 0.2vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  padding-top: 0.5rem;
  background-color: rgba(37, 33, 33, 0.9);
  padding-bottom: 0.5rem;
}

.autoplay_button:hover {
  color: #000;
  background-color: #e6e6e6;
  transition: all 0.2s;
}

/* video::-webkit-media-controls-fullscreen-button {
  display: none;
} */